const NOTIFICATION_STYLES = {
  regular: 'border-notice-400 bg-notice-200',
  warning: 'border-warning-400 bg-warning-200',
  'warning-light': 'border-warning-400',
  success: 'border-success-400 bg-success-200',
};

const NOTIFICATION_SIZES = {
  small: 'pl-3 pr-6 pt-2 md:py-3.5 md:px-6',
  large: 'px-3 pb-3 pt-7 md:py-6 md:px-6',
};

type NotificationStyle = keyof typeof NOTIFICATION_STYLES;

type NotificationSize = keyof typeof NOTIFICATION_SIZES;

const CONTAINER = 'flex items-center border relative flex-col md:flex-row w-full';

export type { NotificationStyle, NotificationSize };

export { NOTIFICATION_STYLES, CONTAINER, NOTIFICATION_SIZES };
