import {
  Action,
  action,
  computed,
  Computed,
  Thunk,
  thunk,
} from 'easy-peasy';
import { API, handleResponse, momentum } from 'libs';
import {
  createContract,
  IContract,
  Consumption,
  buildConsumption,
  Measures,
  MeasureParams,
  Power,
  PowerByMonth,

  buildPower,
  MaxPowerByMonth,
  PowerParams,
  PowerPeriod,
  PowerPeriodType,

  buildReactive,
  ReactiveMeasures,
  ReactiveMeasureParams,
  ReactivePeriodType,
  Reactive,
} from 'models';
import { saveAs } from 'file-saver';

import clients from '../../clients';

interface ConsumptionModel {
  contract: IContract;
  consumption: Consumption;
  from: Date | null;
  to: Date | null;
  iReactive: Reactive;
  iPower: Power;
  year: string;
  rYear: string;
  rMonth: string;
  period: PowerPeriodType;
  reactivePeriod: ReactivePeriodType;

  setDevice: Action<ConsumptionModel, IContract>;
  setFrom: Action<ConsumptionModel, Date | null>;
  setTo: Action<ConsumptionModel, Date | null>;
  setYear: Action<ConsumptionModel, string>;
  setRYear: Action<ConsumptionModel, string>;
  setRMonth: Action<ConsumptionModel, string>;
  setPeriod: Action<ConsumptionModel, PowerPeriodType>;
  setReactivePeriod: Action<ConsumptionModel, ReactivePeriodType>;
  initConsumption: Action<ConsumptionModel, Measures>;
  initReactive: Action<ConsumptionModel, ReactiveMeasures>;
  initPower: Action<ConsumptionModel, PowerByMonth[]>;
  buildExport: Action<ConsumptionModel, string>;

  energy: Thunk<ConsumptionModel, MeasureParams>;
  reactive: Thunk<ConsumptionModel, ReactiveMeasureParams>;
  power: Thunk<ConsumptionModel, PowerParams>;
  export: Thunk<ConsumptionModel, MeasureParams>;
  years: Computed<ConsumptionModel, string[]>;
  powers: Computed<ConsumptionModel, MaxPowerByMonth[]>;
  periods: Computed<ConsumptionModel, PowerPeriod[]>;
  reactivePeriods: Computed<ConsumptionModel, ReactivePeriodType[]>;
}

const consumptionModel: ConsumptionModel = {
  contract: createContract(),
  consumption: buildConsumption(),
  from: momentum().subtract(30, 'days').toDate(),
  to: momentum().toDate(),
  iReactive: buildReactive(),
  iPower: buildPower(),
  year: momentum().format('YYYY'),
  rYear: momentum().format('YYYY'),
  rMonth: momentum().format('MM'),
  period: 'P1',
  reactivePeriod: 'P1',

  setDevice: action((state, payload) => {
    state.contract = payload;
  }),

  setFrom: action((state, payload) => {
    state.from = payload;
  }),

  setTo: action((state, payload) => {
    state.to = payload;
  }),

  setYear: action((state, payload) => {
    state.year = payload;
  }),

  setRYear: action((state, payload) => {
    state.rYear = payload;
  }),

  setRMonth: action((state, payload) => {
    state.rMonth = payload;
  }),

  setPeriod: action((state, payload) => {
    state.period = payload;
  }),

  setReactivePeriod: action((state, payload) => {
    state.reactivePeriod = payload;
  }),

  initConsumption: action((state, payload) => {
    state.consumption = buildConsumption(payload, state.from, state.to);
  }),

  initReactive: action((state, payload) => {
    state.iReactive = buildReactive(payload);
    // eslint-disable-next-line prefer-destructuring
    state.reactivePeriod = state.iReactive.periods[0] || 'P1';
  }),

  initPower: action((state, payload) => {
    state.iPower = buildPower(payload);
  }),

  buildExport: action((state, payload) => {
    const byteChars = atob(payload);
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i += 1) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const name = state.contract.cups || state.contract.deviceID;
    const from = momentum(state.from).format('DD-MM-YY');
    const to = momentum(state.to).format('DD-MM-YY');
    saveAs(file, `${name} ${from} - ${to}.xlsx`);
  }),

  energy: thunk(async (actions, payload) => {
    const response = await API.get(clients)('/measures', { params: payload });
    return handleResponse(response, actions.initConsumption);
  }),

  reactive: thunk(async (actions, payload) => {
    const response = await API.get(clients)('/reactive-measures', { params: payload });
    return handleResponse(response, actions.initReactive);
  }),

  power: thunk(async (actions, payload) => {
    const response = await API.get(clients)('/power', { params: payload });
    return handleResponse(response, actions.initPower);
  }),

  export: thunk(async (actions, payload) => {
    const response = await API.get(clients)('/measures/export', { params: payload });
    return handleResponse(response, actions.buildExport);
  }),

  years: computed((_state) => [
    momentum().format('YYYY'),
    momentum().subtract(1, 'year').format('YYYY'),
    momentum().subtract(2, 'year').format('YYYY'),
  ]),

  powers: computed((state) => state.iPower.power(state.contract)),

  periods: computed((state) => state.iPower.powerPeriods(state.contract, state.powers)),

  reactivePeriods: computed((state) => state.iReactive.periods),
};

export type { ConsumptionModel, PowerByMonth };

export default consumptionModel;
