import _ from 'lodash/fp';

import {
  COLORS,
  LINKS,
  TEXTS,
  Color,
  Setting,
  Text,
  Link,
  Contact,
  ContactNumber,
} from './types';

const DEFAULT_COLORS: Record<Color, string> = {
  primary: '#454545',
  'primary-negative': '#FFFFFF',
  'shade-100': '#E2E8F0',
  'shade-200': '#E2E8F0',
  'shade-300': '#94A3B8',
  'shade-400': '#94A3B8',
  'shade-500': '#475569',
  'shade-600': '#475569',
  'self-100': '#E2E8F0',
  'self-200': '#E2E8F0',
  'self-300': '#94A3B8',
  'self-400': '#94A3B8',
  'self-500': '#475569',
  'self-600': '#475569',
  'divider-400': '#A6A6A6',
  'divider-200': '#E8E8EA',
  'divider-negative': '#FFFFFF',
  'backdrop-primary': '#FBFBFB',
  'backdrop-secondary': '#E9F1F8',
  'backdrop-terciary': '#FAFAFA',
  'backdrop-disabled': '#D4D4D4',
  header: '#130E1E',
  'header-negative': '#FFFFFF',
  footer: '#130E1E',
  'footer-negative': '#FFFFFF',
  content: '#130E1E', // Brand/Black
  interaction: '#0A69B2',
  'content-secondary': '#130E1E',
  'content-negative': '#FFFFFF',
  'content-negative-secondary': '#FFFFFF',
  'content-inactive': '#A6A6A6',
  'content-disabled': '#737373',
};

const colorRGB = (hex: string, type: string): string => {
  const color = _.replace('#', '')(hex);

  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, color.length), 16);

  return `--color-${type}: ${r}, ${g}, ${b};`;
};

const getColor = (colors: Setting[]): ((key: Color) => string) => (
  (key: Color): string => _.flow(
    _.find(['key', key]),
    _.get('value'),
  )(colors)
);

const setColors = (colors: Setting[]): string => _.flow(
  _.map((name: Color) => {
    const color = getColor(colors)(name);
    if (_.isUndefined(color)) {
      return colorRGB(_.get(name)(DEFAULT_COLORS), name);
    }
    return colorRGB(color, name);
  }),
  _.join(''),
)(COLORS);

const getText = (texts: Setting[]): ((key: Text) => string) => (
  (key: Text): string => _.flow(
    _.find(['key', key]),
    _.get('value'),
  )(texts)
);

const getLink = (links: Setting[]): ((key: Link) => string) => (
  (key: Link): string => _.flow(
    _.find(['key', key]),
    _.get('value'),
  )(links)
);

const getContactNumber = (contactNumbers: ContactNumber[]): ((key: Contact) => ContactNumber) => (
  // @ts-ignore
  (key: Contact): ContactNumber => _.find(['key', key])(contactNumbers)
);

export type {
  Setting,
  ContactNumber,
  Text,
  Link,
};

export {
  LINKS,
  TEXTS,
  getColor,
  setColors,
  getText,
  getLink,
  getContactNumber,
};
