import { divide, momentum } from 'libs';
import _ from 'lodash/fp';

import {
  ReactiveMeasures,
  ReactiveMeasureByDay,
  ReactiveMeasureParams,
  ReactivePeriodType,
  ReactiveCalcs,
  ReactiveByDay,
  ReactiveByPeriod,
  Reactive,
} from './types';

const sumEnergyValues = _.flow(
  _.map('value'),
  _.sum,
  divide,
);

const getTotalInductiveReactive = (rbd: ReactiveMeasureByDay[]): number => {
  const total = _.flow(
    _.filter(['reactive', true]),
    sumEnergyValues,
  )(rbd);

  return Math.max(total, 0);
};

const getTotalImported = _.flow(
  _.filter((ibh: ReactiveMeasureByDay) => !ibh.reactive),
  sumEnergyValues,
);

const updateReactive = _.map((rbd: ReactiveMeasureByDay): ReactiveMeasureByDay => ({
  date: momentum(rbd.date).format('YYYY-MM-DD'),
  value: rbd.value,
  period: rbd.period,
  reactive: true,
}));

const updateImported = _.map((ibd: ReactiveMeasureByDay): ReactiveMeasureByDay => ({
  date: momentum(ibd.date).format('YYYY-MM-DD'),
  value: ibd.value,
  period: ibd.period,
  reactive: false,
}));

const calculateTotalReactive = (
  measures: ReactiveMeasureByDay[],
  period: string,
): number => _.flow(
  _.filter(['reactive', true]),
  _.filter(['period', period]),
  sumEnergyValues,
)(measures);

const calculateTotalImported = (
  measures: ReactiveMeasureByDay[],
  period: string,
): number => _.flow(
  _.filter(['reactive', false]),
  _.filter(['period', period]),
  sumEnergyValues,
)(measures);

const calculateChartData = (
  totalReactive: number,
  totalImported: number,
): Omit<ReactiveCalcs, 'period'> => {
  const over33Threshold = totalImported * 0.33;
  const over75Threshold = totalImported * 0.75;

  const isReactiveOver33 = totalReactive > over33Threshold;
  const isReactiveOver75 = totalReactive > over75Threshold;

  const chartGreenArea = isReactiveOver33 ? over33Threshold : totalReactive;
  const chartOrangeArea = chartGreenArea === over33Threshold
    ? Math.min(over75Threshold, totalReactive) - over33Threshold
    : 0;
  const chartRedArea = chartGreenArea + chartOrangeArea === over75Threshold
    ? Math.min(totalReactive, totalImported) - over75Threshold
    : 0;
  const chartFillArea = Math.max(
    totalImported - chartGreenArea - chartOrangeArea - chartRedArea,
    0,
  );

  return {
    totalReactive,
    totalImported,
    overPercentage: totalReactive / totalImported,
    over33Threshold,
    over75Threshold,
    isReactiveOver33,
    isReactiveOver75,
    chartGreenArea,
    chartOrangeArea,
    chartRedArea,
    chartFillArea,
  };
};

const calculateReactiveByDay = (
  measures: ReactiveMeasureByDay[],
  period: string,
  periodName: ReactivePeriodType,
): ReactiveByDay[] => {
  const data = _.flow(
    _.groupBy('date'),
    _.toPairs,
    _.map((item: [string, ReactiveMeasureByDay[]]): ReactiveByDay => {
      const totalReactive = calculateTotalReactive(item[1], period);
      const totalImported = calculateTotalImported(item[1], period);

      return {
        date: item[0],
        period: periodName,
        ...calculateChartData(totalReactive, totalImported),
      };
    }),
    _.orderBy('date', 'asc'),
  )(measures);

  return data;
};

const calculateReactive = (
  measures: ReactiveMeasureByDay[],
  period: string,
  periodName: ReactivePeriodType,
): ReactiveByPeriod => {
  const totalReactive = calculateTotalReactive(measures, period);
  const totalImported = calculateTotalImported(measures, period);

  return {
    period: periodName,
    ...calculateChartData(totalReactive, totalImported),
    days: calculateReactiveByDay(measures, period, periodName),
  };
};

const calculatePeriods = _.flow(
  _.filter((item: ReactiveMeasureByDay) => item.period !== '6'),
  _.map('period'),
  _.uniq,
  _.sortBy(_.identity),
  _.map((period: string) => `P${period}` as ReactivePeriodType),
);

const buildReactive = (
  data: ReactiveMeasures = { reactive: [], imported: [] },
): Reactive => {
  const reactiveMeasures = updateReactive(data.reactive);
  const importedMeasures = updateImported(data.imported);

  const measures = [...reactiveMeasures, ...importedMeasures];

  return {
    reactive: {
      P1: calculateReactive(measures, '1', 'P1'),
      P2: calculateReactive(measures, '2', 'P2'),
      P3: calculateReactive(measures, '3', 'P3'),
      P4: calculateReactive(measures, '4', 'P4'),
      P5: calculateReactive(measures, '5', 'P5'),
    },
    totalImported: getTotalImported(importedMeasures),
    totalReactive: getTotalInductiveReactive(reactiveMeasures),
    periods: calculatePeriods(measures),
  };
};

export type {
  ReactiveMeasures,
  ReactiveMeasureByDay,
  ReactiveMeasureParams,
  ReactivePeriodType,
  ReactiveByDay,
  ReactiveByPeriod,
  Reactive,
};

export default buildReactive;
