import { saveAs } from 'file-saver';
import { AppDocument } from 'models';
import _ from 'lodash/fp';

import t from '../locales';

const classNames = (...classes: (string | undefined)[]): string => classes.filter(Boolean).join(' ');

const download = (document: AppDocument): void => {
  const extension = _.flow(
    _.split('.'),
    _.last,
  )(document.filename);

  saveAs(document.url, `${t(`models.documents.${document.docType}`)}.${extension}`);
};

const fancyNumber = (value: number, fixed = 1): string => {
  const fixedNumber = parseFloat(value.toFixed(fixed));

  return fixedNumber.toLocaleString();
};

const divide = (value: number): number => (
  _.isEqual(value, 0) ? value : value / 1000
);

export {
  classNames,
  download,
  fancyNumber,
  divide,
};
