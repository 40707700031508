import Cookies from 'universal-cookie';

const COOKIE_NAMES = {
  client: 'gridfy_client_session',
  manager: 'gridfy_manager_session',
  advice: 'gridfy_advice',
  consumption: 'gridfy_consumption',
};

type CookieName = keyof typeof COOKIE_NAMES;

const cookies = new Cookies();

const set = (name: CookieName, value: string, expires: Date): void => (
  cookies.set(COOKIE_NAMES[name], value, { expires, path: '/', sameSite: true })
);

const get = (name: CookieName): string => (
  cookies.get(COOKIE_NAMES[name])
);

const remove = (name: CookieName): void => (
  cookies.remove(COOKIE_NAMES[name], { path: '/', sameSite: true })
);

const COOKIES = {
  set,
  get,
  remove,
};

export default COOKIES;
