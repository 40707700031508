import { action, Action } from 'easy-peasy';
import { COOKIES, momentum } from 'libs';

interface AppModel {
  title: string;
  label: string;
  close: string;
  back: string | (() => void);
  advice: boolean;

  setTitle: Action<AppModel, string>;
  setLabel: Action<AppModel, string>;
  setClose: Action<AppModel, string>;
  setBack: Action<AppModel, string | (() => void)>;
  closeAdvice: Action<AppModel>;
  initAdvice: Action<AppModel>;
}

const appModel: AppModel = {
  title: '',
  close: '',
  back: '',
  label: '',
  advice: false,

  setTitle: action((state, payload) => {
    state.title = payload;
  }),

  setLabel: action((state, payload) => {
    state.label = payload;
  }),

  setClose: action((state, payload) => {
    state.close = payload;
  }),

  setBack: action((state, payload) => {
    state.back = payload;
  }),

  initAdvice: action((state) => {
    const adviceCookie = COOKIES.get('consumption');
    if (adviceCookie === undefined) {
      state.advice = true;
      return;
    }

    state.advice = false;
  }),

  closeAdvice: action((state) => {
    COOKIES.set('consumption', '1', momentum().add(1, 'year').toDate());
    state.advice = false;
  }),
};

export type { AppModel };

export default appModel;
