import { TagStyles } from 'components/tags';
import { Client } from 'models/client';
import { AppDocument } from 'models/document';
import { INonPaymentCut, NonPaymentCut } from 'models/non-payment-cut';
import { ScheduledCut } from 'models/scheduled-cuts/types';

const OWNERS = ['HOLDER', 'AUTHORIZED'] as const;

export type Owner = typeof OWNERS[number];

type Alias = {
  id: number;
  name: string;
  cups: string;
}

interface Contract {
  id: string;
  alias: Alias;
  owner: Owner;
  name: string;
  identityNumber: string;
  cups: string;
  cupsType: string;
  status: string;
  tariff: string;
  address: string;
  postalCode: string;
  city: string;
  province: string;
  municipality: string;
  pcp1: number | null;
  pcp2: number | null;
  pcp3: number | null;
  pcp4: number | null;
  pcp5: number | null;
  pcp6: number | null;
  meterTecnologyType: string;
  type: string;
  voltage: string;
  maxInstallationPower: string;
  deviceID: string;
  validated?: boolean;
  authorizedID?: number;
  nonPaymentCut?: NonPaymentCut | null;
  scheduledCuts: ScheduledCut[];
}

interface Authorization {
  id?: number;
  createdAt?: string;
  rejected: boolean;
  owner: Owner;
  name: string;
  cups: string;
  identityNumber: string;
  validated: boolean;
  documents?: AppDocument[];
  client?: Client;
}

interface IContract extends Contract {
  location: string;
  powerHigh: string;
  powerLow: string;
  holder: boolean;
  naming: string;
  state: TagStyles;
  power: string;
  tension: string;
  maxPower: number;
  unpayment?: INonPaymentCut | null;
  industrial: boolean;
  show: boolean;
}

export { OWNERS };

export type {
  Contract,
  IContract,
  Authorization,
  Alias,
};
