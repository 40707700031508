const SQUARED_SIZES = {
  'x-small-growth': 'w-6 min-w-[24px] lg:w-8 lg:min-w-[32px]',
  small: 'w-9 min-w-[36px]',
  'small-growth': 'w-9 min-w-[36px] lg:w-12 lg:min-w-[48px]',
  medium: 'w-[38px] min-w-[38px]',
  'large-growth': 'w-[45px] min-w-[45px] lg:w-[61px] lg:min-w-[61px]',
  'x-large': 'w-16 min-w-[64px]',
};

type SquareSize = keyof typeof SQUARED_SIZES;

const SQUARED_STYLES = {
  regular: 'bg-backdrop-secondary text-content',
  disabled: 'bg-backdrop-disabled text-content-disabled',
  info: 'bg-notice-400 text-content-negative',
  warning: 'bg-warning-400 text-content-negative',
  success: 'bg-success-400 text-content-negative',
};

type SquareStyle = keyof typeof SQUARED_STYLES;

const SQUARED = 'flex items-center justify-center rounded-lg aspect-square';

export { SQUARED_SIZES, SQUARED_STYLES, SQUARED };

export type { SquareSize, SquareStyle };
