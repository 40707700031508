import { divide, momentum, t } from 'libs';
import _ from 'lodash/fp';
import { IContract } from 'models/contract';

import {
  PowerByMonth,
  MaxPowerByMonth,
  Power,
  PowerParams,
  PowerPeriod,
  PowerPeriodType,
  PowerHiredPeriod,
} from './types';

const calculatePower = (
  pbm: PowerByMonth[],
  period: string,
  max: number | null,
): [number, number, number] => {
  const total = _.flow(
    _.filter(['period', period]),
    _.map('value'),
    _.max,
    divide,
  )(pbm);

  if (_.isNil(max) || max === 0 || _.isNaN(total)) return [0, 0, 0];

  if (total <= max) return [total, 0, max];

  return [max, total - max, max];
};

const setPowerValue = (value: number | null, total: number): string => (
  total === 0 ? '' : `${value}`
);

const powerPeriods = (contract: IContract, powers: MaxPowerByMonth[]): PowerPeriod[] => [
  {
    value: setPowerValue(contract.pcp1, _.sumBy('p1')(powers)),
    name: contract.industrial ? 'P1' : 'Periodo Punta',
  },
  {
    value: setPowerValue(contract.pcp2, _.sumBy('p2')(powers)),
    name: 'P2',
  },
  {
    value: setPowerValue(contract.pcp3, _.sumBy('p3')(powers)),
    name: contract.industrial ? 'P3' : 'Periodo Valle',
  },
  {
    value: setPowerValue(contract.pcp4, _.sumBy('p4')(powers)),
    name: 'P4',
  },
  {
    value: setPowerValue(contract.pcp5, _.sumBy('p5')(powers)),
    name: 'P5',
  },
  {
    value: setPowerValue(contract.pcp6, _.sumBy('p6')(powers)),
    name: 'P6',
  },
];

const powers = (power: PowerByMonth[]): ((contract: IContract) => MaxPowerByMonth[]) => (
  (contract: IContract): MaxPowerByMonth[] => _.flow(
    _.groupBy('date'),
    _.toPairs,
    _.map((item: [string, PowerByMonth[]]): MaxPowerByMonth => {
      const [p1, p1Over, maxP1] = calculatePower(item[1], '1', contract.pcp1);
      const [p2, p2Over, maxP2] = calculatePower(item[1], '2', contract.pcp2);
      const [p3, p3Over, maxP3] = calculatePower(item[1], '3', contract.pcp3);
      const [p4, p4Over, maxP4] = calculatePower(item[1], '4', contract.pcp4);
      const [p5, p5Over, maxP5] = calculatePower(item[1], '5', contract.pcp5);
      const [p6, p6Over, maxP6] = calculatePower(item[1], '6', contract.pcp6);
      return {
        date: momentum(item[0]).subtract(2, 'hours').format('YYYY-MM-DD HH:mm'),
        p1,
        p1Over,
        maxP1,
        p2,
        p2Over,
        maxP2,
        p3,
        p3Over,
        maxP3,
        p4,
        p4Over,
        maxP4,
        p5,
        p5Over,
        maxP5,
        p6,
        p6Over,
        maxP6,
      };
    }),
    _.orderBy(['date'], ['asc']),
  )(power)
);

const buildPower = (power: PowerByMonth[] = []): Power => ({
  power: powers(power),
  powerPeriods,
});

const getPowerPeriod = (period: string) => _.flow(
  _.groupBy(period),
  _.toPairs,
  _.map((item: [number, MaxPowerByMonth[]]): { value: number, dates: string[] } => ({
    value: item[0],
    dates: _.map('date')(item[1]),
  })),
  _.map((item: { value: number, dates: string[] }): PowerHiredPeriod => ({
    value: item.value,
    period: t('clients.consumptions.calendar_detail', {
      from: momentum(item.dates[0]).format('D MMMM YYYY'),
      to: momentum(item.dates[item.dates.length - 1]).format('D MMMM YYYY'),
    }),
  })),
);

export type {
  PowerByMonth,
  MaxPowerByMonth,
  Power,
  PowerPeriod,
  PowerParams,
  PowerPeriodType,
  PowerHiredPeriod,
};

export { getPowerPeriod };

export default buildPower;
