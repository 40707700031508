import { ID } from 'models';

const PATHS = {
  index: '/',
  supply: {
    index: 'suministro-electrico/',
    wizard: 'asistente-de-nuevas-altas',
    supply: 'proceso-nuevo-punto-de-suministro',
    cups: 'proceso-solicitud-de-cups',
  },
  selfconsumption: {
    index: 'autoconsumo/',
    procedures: 'tramitaciones',
  },
  generation: 'generacion-de-energia',
  nodal: 'capacidades-nodales',
  contact: {
    index: 'contacto/',
    bookings: 'agendar-llamada',
    message: 'enviar-mensaje',
    fraud: 'reportar-un-fraude',
    incidents: 'incidentes',
    call: 'llamanos',
  },
  normative: 'normativa',
  session: 'acceder',
  validation: 'validar-email',
  revalidate: 'reenviar-email/:email',
  register: 'nuevo-registro',
  welcome: 'bienvenido',
  password: {
    reset: 'recuperar-contrasena',
    restore: 'restaurar-contrasena',
  },
  profile: {
    index: 'perfil/',
    edit: 'editar-perfil',
    validation: 'validar-perfil',
  },
  contracts: {
    new: 'contratos/anadir-contrato',
    show: 'contratos/:id',
  },
  authorized: {
    new: 'autorizados/anadir-autorizado',
    added: 'autorizados/autorizado-anadido',
  },
  breakdowns: {
    index: 'averias-y-cortes',
    all: 'todas',
  },
  consumption: {
    index: 'mi-consumo/',
    power: 'potencia',
    reactive: 'energia-reactiva',
    reduce: 'consejos-para-ahorrar',
    tariffs: 'ver-horarios-tarifas',
    industrialTariffs: 'ver-horarios-tarifas-industriales',
    dailyConsumption: 'detalle-horario',
    penalties: 'penalizaciones',
  },
  requests: {
    index: 'mis-solicitudes/',
    all: 'todas',
    show: {
      index: ':id/',
      appointment: 'agendar-llamada',
    },
    cups: 'solicitud-cups',
    supply: 'solicitud-nuevo-punto-de-suministro',
    cau: 'solicitud-cau',
    selfconsumption: 'solicitud-autoconsumo',
    generation: 'solicitud-generacion',
    supplycups: 'solicitud-suministro-electrico',
  },
};

const ROUTES = {
  index: '/',
  supply: {
    index: '/suministro-electrico',
    wizard: '/suministro-electrico/asistente-de-nuevas-altas',
    supply: '/suministro-electrico/proceso-nuevo-punto-de-suministro',
    cups: '/suministro-electrico/proceso-solicitud-de-cups',
  },
  selfconsumption: {
    index: '/autoconsumo',
    procedures: '/autoconsumo/tramitaciones',
  },
  generation: '/generacion-de-energia',
  nodal: '/capacidades-nodales',
  contact: {
    index: '/contacto',
    bookings: '/contacto/agendar-llamada',
    message: '/contacto/enviar-mensaje',
    fraud: '/contacto/reportar-un-fraude',
    incidents: '/contacto/incidentes',
    call: '/contacto/llamanos',
  },
  normative: '/normativa',
  session: '/acceder',
  validation: '/validar-email',
  revalidate: '/reenviar-email/:email',
  register: '/nuevo-registro',
  welcome: '/bienvenido',
  password: {
    reset: '/recuperar-contrasena',
    restore: '/restaurar-contrasena',
  },
  profile: {
    index: '/perfil',
    edit: '/perfil/editar-perfil',
    validation: '/perfil/validar-perfil',
  },
  contracts: {
    new: '/contratos/anadir-contrato',
    show: (id: ID): string => `/contratos/${id}`,
  },
  authorized: {
    new: '/autorizados/anadir-autorizado',
    added: '/autorizados/autorizado-anadido',
  },
  breakdowns: {
    index: '/averias-y-cortes',
    all: '/averias-y-cortes/todas',
  },
  consumption: {
    index: '/mi-consumo',
    power: '/mi-consumo/potencia',
    reactive: '/mi-consumo/energia-reactiva',
    reduce: '/mi-consumo/consejos-para-ahorrar',
    tariffs: '/mi-consumo/ver-horarios-tarifas',
    industrialTariffs: '/mi-consumo/ver-horarios-tarifas-industriales',
    dailyConsumption: '/mi-consumo/detalle-horario',
    penalties: '/mi-consumo/penalizaciones',
  },
  requests: {
    index: '/mis-solicitudes/',
    all: '/mis-solicitudes/todas',
    show: {
      index: (id: ID): string => `/mis-solicitudes/${id}`,
      appointment: (id: ID): string => `/mis-solicitudes/${id}/agendar-llamada`,
    },
    cups: '/mis-solicitudes/solicitud-cups',
    supply: '/mis-solicitudes/solicitud-nuevo-punto-de-suministro',
    cau: '/mis-solicitudes/solicitud-cau',
    selfconsumption: '/mis-solicitudes/solicitud-autoconsumo',
    generation: '/mis-solicitudes/solicitud-generacion',
    supplycups: '/mis-solicitudes/solicitud-suministro-electrico',
  },
};

export { PATHS, ROUTES };
