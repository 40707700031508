import { TagStyles } from 'components/tags';
import _ from 'lodash/fp';
import createNonPaymentCut from 'models/non-payment-cut';

import {
  Contract,
  IContract,
  OWNERS,
  Authorization,
  Alias,
} from './types';

const MAX_POWER = 15;

const defaultContract: Contract = {
  id: '',
  alias: { id: 0, name: '', cups: '' },
  owner: 'HOLDER',
  name: '',
  identityNumber: '',
  cups: '',
  cupsType: '',
  status: '',
  tariff: '',
  address: '',
  postalCode: '',
  city: '',
  province: '',
  municipality: '',
  pcp1: 0,
  pcp2: 0,
  pcp3: 0,
  pcp4: 0,
  pcp5: 0,
  pcp6: 0,
  meterTecnologyType: '',
  type: '',
  voltage: '',
  maxInstallationPower: '',
  deviceID: '',
  scheduledCuts: [],
};

const setLocation = (contract: Contract): string => (
  `${contract.address}, ${contract.postalCode} - ${contract.municipality}, ${contract.province}`
);

const contratstate = (state: string): TagStyles => {
  if (_.isEqual(state, 'A')) return 'success';
  if (_.isEqual(state, 'S')) return 'warning';
  if (_.isEqual(state, 'D')) return 'danger';
  return 'notice';
};

const maxPower = _.flow(
  _.pick(['pcp1', 'pcp2', 'pcp3', 'pcp4', 'pcp5', 'pcp6']),
  _.valuesIn,
  _.map(parseFloat),
  _.compact,
  _.max,
);

const industrial = (contract: Contract): boolean => maxPower(contract) > MAX_POWER;

const show = (contractStatus: string): boolean => _.isEqual(contractStatus, 'A');

const powerHigh = (contract: Contract): string => {
  if (_.isNil(contract.pcp4)) {
    return contract.pcp1?.toLocaleString() || '';
  }

  return contract.pcp4.toLocaleString();
};

const powerLow = (contract: Contract): string => {
  if (_.isNil(contract.pcp6)) {
    return contract.pcp3?.toLocaleString() || '';
  }

  return contract.pcp6.toLocaleString();
};

const createContract = (contract = defaultContract): IContract => ({
  ...contract,
  location: setLocation(contract),
  powerHigh: powerHigh(contract),
  powerLow: powerLow(contract),
  holder: contract.owner === OWNERS[0],
  naming: contract.alias.name || contract.address,
  state: contratstate(contract.status),
  power: parseFloat(contract.maxInstallationPower).toLocaleString(),
  tension: parseFloat(contract.voltage).toLocaleString(),
  maxPower: maxPower(contract),
  unpayment: _.isNil(contract.nonPaymentCut) ? null : createNonPaymentCut(contract.nonPaymentCut),
  industrial: industrial(contract),
  show: show(contract.status),
});

export { createContract, OWNERS };

export type {
  Contract,
  IContract,
  Authorization,
  Alias,
};
