import _ from 'lodash/fp';
import { divide, momentum, t } from 'libs';
import { IContract } from 'models/contract';
import { IClientCompany } from 'models/company';

import {
  Consumption,
  ConsumptionByDay,
  ConsumptionByHour,
  EnergyByHour,
  Measures,
  PieData,
  MeasureParams,
} from './types';

const defaultConsumptionByDay: ConsumptionByDay = {
  date: '',
  p1: 0,
  p2: 0,
  p3: 0,
  p4: 0,
  p5: 0,
  p6: 0,
  s1: 0,
  s2: 0,
  s3: 0,
  s4: 0,
  s5: 0,
  s6: 0,
  totalExported: 0,
  totalImported: 0,
  hours: [],
};

const groupEnergies = _.flow(
  _.map('value'),
  _.sum,
  divide,
);

const getTotalExported = _.flow(
  _.filter((ebh: EnergyByHour) => ebh.exported),
  groupEnergies,
);

const getTotalImported = _.flow(
  _.filter((ebh: EnergyByHour) => !ebh.exported),
  groupEnergies,
);

const updateExported = _.map((ebh: EnergyByHour): EnergyByHour => ({
  date: momentum(ebh.date).format('YYYY-MM-DD'),
  hour: momentum(ebh.date).format('HH'),
  time: momentum(ebh.date).format('HH:mm'),
  value: ebh.value,
  period: ebh.period,
  exported: true,
}));

const updateImported = _.map((ebh: EnergyByHour): EnergyByHour => ({
  date: momentum(ebh.date).format('YYYY-MM-DD'),
  hour: momentum(ebh.date).format('HH'),
  time: momentum(ebh.date).format('HH:mm'),
  value: ebh.value,
  period: ebh.period,
  exported: false,
}));

const consumptionByPeriod = (ebhs: EnergyByHour[], period: string, self = false) => _.flow(
  _.filter((ebh: EnergyByHour) => _.isEqual(ebh.period, period) && _.isEqual(ebh.exported, self)),
  _.map('value'),
  _.sum,
  divide,
)(ebhs);

const unifyDay = _.flow(
  _.groupBy('hour'),
  _.toPairs,
  _.map((item: [string, EnergyByHour[]]): ConsumptionByHour => ({
    hour: item[0],
    p1: consumptionByPeriod(item[1], '1'),
    p2: consumptionByPeriod(item[1], '2'),
    p3: consumptionByPeriod(item[1], '3'),
    p4: consumptionByPeriod(item[1], '4'),
    p5: consumptionByPeriod(item[1], '5'),
    p6: consumptionByPeriod(item[1], '6'),
    s1: consumptionByPeriod(item[1], '1', true),
    s2: consumptionByPeriod(item[1], '2', true),
    s3: consumptionByPeriod(item[1], '3', true),
    s4: consumptionByPeriod(item[1], '4', true),
    s5: consumptionByPeriod(item[1], '5', true),
    s6: consumptionByPeriod(item[1], '6', true),
    totalExported: getTotalExported(item[1]),
    totalImported: getTotalImported(item[1]),
    ibh: _.flow(
      _.filter((ebh: EnergyByHour) => !ebh.exported),
      _.map((ebh: EnergyByHour): EnergyByHour => ({
        date: ebh.date,
        hour: ebh.hour,
        time: ebh.time,
        value: divide(ebh.value),
        period: ebh.period,
        exported: ebh.exported,
      })),
    )(item[1]),
    ebh: _.flow(
      _.filter((ebh: EnergyByHour) => ebh.exported),
      _.map((ebh: EnergyByHour): EnergyByHour => ({
        date: ebh.date,
        hour: ebh.hour,
        time: ebh.time,
        value: divide(ebh.value),
        period: ebh.period,
        exported: ebh.exported,
      })),
    )(item[1]),
  })),
  _.orderBy(['hour'], ['asc']),
);

const unifyData = _.flow(
  _.groupBy('date'),
  _.toPairs,
  _.map((item: [string, EnergyByHour[]]): ConsumptionByDay => ({
    date: item[0],
    p1: consumptionByPeriod(item[1], '1'),
    p2: consumptionByPeriod(item[1], '2'),
    p3: consumptionByPeriod(item[1], '3'),
    p4: consumptionByPeriod(item[1], '4'),
    p5: consumptionByPeriod(item[1], '5'),
    p6: consumptionByPeriod(item[1], '6'),
    s1: consumptionByPeriod(item[1], '1', true),
    s2: consumptionByPeriod(item[1], '2', true),
    s3: consumptionByPeriod(item[1], '3', true),
    s4: consumptionByPeriod(item[1], '4', true),
    s5: consumptionByPeriod(item[1], '5', true),
    s6: consumptionByPeriod(item[1], '6', true),
    totalExported: getTotalExported(item[1]),
    totalImported: getTotalImported(item[1]),
    hours: unifyDay(item[1]),
  })),
  _.orderBy(['date'], ['asc']),
);

const totalImported = _.flow(
  _.map('totalImported'),
  _.sum,
);

const totalExported = _.flow(
  _.map('totalExported'),
  _.sum,
);

const hasEmptyDays = _.flow(
  _.filter((ebd: ConsumptionByDay) => _.isEqual(ebd.totalImported, 0)),
  _.size,
);

const calculateTotal = (data: ConsumptionByDay[], field: string): number => _.flow(
  // @ts-ignore
  _.map((ebd: ConsumptionByDay) => ebd[field]),
  _.sum,
)(data);

const consumptionPie = (consumption: ConsumptionByDay[]): (
  (company: IClientCompany, contract: IContract) => PieData[]
) => (
  (company: IClientCompany, contract: IContract): PieData[] => [
    {
      name: contract.industrial ? 'P1' : t('clients.consumptions.peak_name'),
      value: calculateTotal(consumption, 'p1'),
      color: company.color('shade-600'),
      style: 'bg-shade-600',
    },
    {
      name: contract.industrial ? 'P2' : t('clients.consumptions.flat_name'),
      value: calculateTotal(consumption, 'p2'),
      color: contract.industrial ? company.color('shade-500') : company.color('shade-300'),
      style: contract.industrial ? 'bg-shade-500' : 'bg-shade-300',
    },
    {
      name: contract.industrial ? 'P3' : t('clients.consumptions.off_peak_name'),
      value: calculateTotal(consumption, 'p3'),
      color: contract.industrial ? company.color('shade-400') : company.color('shade-100'),
      style: contract.industrial ? 'bg-shade-400' : 'bg-shade-100',
    },
    {
      name: 'P4',
      value: calculateTotal(consumption, 'p4'),
      color: company.color('shade-300'),
      style: 'bg-shade-300',
    },
    {
      name: 'P5',
      value: calculateTotal(consumption, 'p5'),
      color: company.color('shade-200'),
      style: 'bg-shade-200',
    },
    {
      name: 'P6',
      value: calculateTotal(consumption, 'p6'),
      color: company.color('shade-100'),
      style: 'bg-shade-100',
    },
  ]
);

const selfPie = (consumption: ConsumptionByDay[]): (
  (company: IClientCompany, contract: IContract) => PieData[]
) => (
  (company: IClientCompany, contract: IContract): PieData[] => [
    {
      name: contract.industrial ? 'P1' : t('clients.consumptions.peak_name'),
      value: calculateTotal(consumption, 's1'),
      color: company.color('self-600'),
      style: 'bg-self-600',
    },
    {
      name: contract.industrial ? 'P2' : t('clients.consumptions.flat_name'),
      value: calculateTotal(consumption, 's2'),
      color: contract.industrial ? company.color('self-500') : company.color('self-300'),
      style: contract.industrial ? 'bg-self-500' : 'bg-self-300',
    },
    {
      name: contract.industrial ? 'P3' : t('clients.consumptions.off_peak_name'),
      value: calculateTotal(consumption, 's3'),
      color: contract.industrial ? company.color('self-400') : company.color('self-100'),
      style: contract.industrial ? 'bg-self-400' : 'bg-self-100',
    },
    {
      name: 'P4',
      value: calculateTotal(consumption, 's4'),
      color: company.color('self-300'),
      style: 'bg-self-300',
    },
    {
      name: 'P5',
      value: calculateTotal(consumption, 's5'),
      color: company.color('self-200'),
      style: 'bg-self-200',
    },
    {
      name: 'P6',
      value: calculateTotal(consumption, 's6'),
      color: company.color('self-100'),
      style: 'bg-self-100',
    },
  ]
);

const isCurrentPeriod = (consumption: ConsumptionByDay[]): boolean => {
  const selectedPeriod = [momentum(_.first(consumption)?.date).format('YYYY-MM-DD'), momentum(_.last(consumption)?.date)];
  const currentPeriod = momentum().startOf('month').format('YYYY-MM-DD');
  const isBetween = momentum(currentPeriod).isBetween(selectedPeriod[0], selectedPeriod[1], null, '[]');
  return isBetween;
};

const buildConsumption = (
  measures: Measures = { imported: [], exported: [] },
  from: Date | null = null,
  to: Date | null = null,
): Consumption => {
  const imported: EnergyByHour[] = updateImported(measures.imported);
  const exported: EnergyByHour[] = updateExported(measures.exported);
  const measure: EnergyByHour[] = _.concat(imported, exported);
  const consumption: ConsumptionByDay[] = unifyData(measure);
  const totalDays = momentum(to).diff(from, 'days');
  return {
    consumption,
    totalImported: totalImported(consumption),
    totalExported: totalExported(consumption),
    isExported: totalExported(consumption) > 0,
    isEmptyDays: (
      (hasEmptyDays(consumption) > 0 || _.size(consumption) < totalDays)
      && isCurrentPeriod(consumption)
    ),
    consumptionPie: consumptionPie(consumption),
    selfPie: selfPie(consumption),
  };
};

export type {
  Consumption,
  ConsumptionByDay,
  ConsumptionByHour,
  EnergyByHour,
  Measures,
  PieData,
  MeasureParams,
};

export { defaultConsumptionByDay };

export default buildConsumption;
